import { React, useContext, useEffect, useState, useCallback } from 'react'
import { Geolocation } from '@capacitor/geolocation';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { useForm, Controller } from "react-hook-form";
import { Preferences } from '@capacitor/preferences';
import { CommonContext } from '../contexts/CommonContext';
import { motion } from 'framer-motion'
import { addNewAddress, getAllUserAddress, getBranchInfo, getDeliveryCharge, getNearestStoreDetails, getStockAvailability, getUserDeliveryData, logAction } from '../services/api';
import { getFirebaseError } from '../services/error-codes';
import { AuthContext } from '../contexts/AuthContext';
import  ComponentLoader from '../components/ComponentLoader'
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Capacitor } from '@capacitor/core';
import NavHeader from '../components/NavHeader';
import { useLocation } from 'react-router-dom'


const styles = {
  titleCont : {
    fontSize : '25px',
    marginBottom:'10px',
    fontFamily:'Foregen',
    color:'#404040'
  }
}

const placesLibrary = ["places"];


function Delivery() {

  const containerStyle = {
    width: '80vw',
    maxWidth:'560px',
    height: '400px'
  };

  const navigate = useNavigate()

  const [showNewAddress, setShowNewAddress] = useState(false)
  const { register, handleSubmit, control, reset, formState : {errors} } = useForm()
  const { showLoader, hideLoader, showAlert, showSnackbar, cartData } = useContext(CommonContext)
  const { getUserId, logout } = useContext(AuthContext)
  const [newAddressDetail, setNewAddressDetail] = useState({})
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [paymentMode, setPaymentMode] = useState('online')
  const { getCartData } = useContext(CommonContext)
  const [loading, setLoading] = useState(true)
  const [address, setAddress] = useState([])
  const [latLong, setLatLong] = useState({})
  const location = useLocation()
  const [searchResult, setSearchResult] = useState("Result: none")

  const [selectedAddrIndex, setSelectedAddrIndex] = useState(null)

  const [delSlot, setDelSlot] = useState(null)
  const [delType, setDelType] = useState('delivery')
  const [delDate, setDelDate] = useState('Today')
  const [storeDetails, setStoreDetails] = useState(null)
  const [map, setMap] = useState(null)
  const [enableSelfPickup, setEnableSelfPickup] = useState(true)

  const [allDeliverySlots, setAllDeliverySlots] = useState([])
  const [filteredSlots, setFilteredSlots] = useState([])
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [hasCoupon, setHasCoupon] = useState(false)
  const [stockMap, setStockMap] = useState({})

  const onDelDateChange = (event, newDate) => {
    setDelSlot(null)
    setDelDate(newDate)
    if (!selectedAddrIndex) return
    showLoader()
    fetchBranchInfo(newDate, storeDetails.branchId)
  }

  const changeDeliverySlot = (newSlot) => {
    setDelSlot(newSlot)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: placesLibrary
  })


  const fetchDeliveryCharge = async() => {
    setDeliveryCharge(await getDeliveryCharge())
    const couponData = await Preferences.get({ key: 'couponData'})
    if (couponData.value) {
      if (JSON.parse(couponData.value).couponCode == 'HEALTHYEATS' || JSON.parse(couponData.value).couponCode == 'HEALTHYEGGS') {
        setHasCoupon(true)
      } 
    }
  } 

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 17.3616,
      lng: 78.4747
    })
    map.fitBounds(bounds)
    map.setCenter(bounds.getCenter())
    setMap(map)
  }, [])

  const mapPositionChanged = (e) => {
    setLatLong({
      lat : e.lat(),
      lng : e.lng()
    })
  }

  function onAutoCompleteLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {

    if (searchResult != null) {

      const place = searchResult.getPlace()
      window['currentLocation'] = {
        lat : place.geometry.location.lat(),
        lng : place.geometry.location.lng()
      }
      setLatLong(window['currentLocation'])
    } else {
      showAlert("Please enter address for map", "error")
    }
  }

  useEffect(() => {
    logAction('PAGE_VIEW', 'delivery')
    getUserAddress()
    fetchDeliveryCharge()
    window['currentLocation'] = {lat : 17.387140, lng : 78.491684}
  }, [])

  const printCurrentPosition = async() => {

   await Geolocation.getCurrentPosition()
    .then((resp) => {
      // console.log("current position is : ", resp.coords)
      setLatLong({
        lat : resp.coords.latitude,
        lng : resp.coords.longitude
      })
      window['currentLocation'] = {
        lat : resp.coords.latitude,
        lng : resp.coords.longitude
      }      
    })
    .catch((err) => {
      requestForLocPermission()
    })
  }

  const requestForLocPermission = async() => {
    await Geolocation.requestPermissions('location').then((resp) => {
      if (resp.location == 'granted')
        printCurrentPosition()
      else 
        showAlert("Location permission is disabled. Please enable location to continue")
    }).catch((err) => {
      showAlert("Location permission is disabled. Please enable location to continue")
    })
  }


  const getUserAddress = async() => { 

    getUserDeliveryData(await getUserId()).then((response) => {
      setAddress(response.addresses)
      // setAllDeliverySlots(response.timeSlots)
      // setFilteredSlots(response.timeSlots.filter((slot) => slot.delTime == delDate))
      setLoading(false)
    }).catch((error) => {
      // setLoading(false)
      logout()
      showAlert("Unexpected error occured. For any issues/enquiries please contact customer care")
    })  
  }

  const onFormSubmit = async(data) => {

    data.houseDetails  = data.houseDetails.replace("'", "")
    data.landmark      = data.landmark.replace("'", "")
    data.streetDetails = data.streetDetails.replace("'", "")

    data.userId    = await getUserId()
    data.timeStamp = Date.now()
    data.latLong   = latLong
       
    if (!latLong.lat || !latLong.lng) {
      showAlert("Please select your delivery location on map to add address.")
      return
    }

    showLoader()
    addNewAddress(data, false).then((response => {
      getUserAddress()
      hideLoader()
    })).catch((error) => {
      hideLoader()
      showAlert(getFirebaseError(error))
    })    

    reset()
    setShowNewAddress(false)
  }

  const closeAddNewAddrForm = async() => {
    setShowNewAddress(false)
    reset()
  }

  const changeDeliveryType = (e) => {
    setDelType(e.target.value)
    setPaymentMode('online')
  }

  const confirmOrder = async() => {
    if (!deliveryAddress) {
      showSnackbar("Please select an address to continue", "error")
      return
    }

    if (!delSlot) {
      showSnackbar("Please select a delivery slot", "error")
      return
    }

    if (!delDate) {
      showSnackbar("Please select a delivery date", "error")
      return
    }

    if (!paymentMode) {
      showSnackbar("Please select a payment mode", "error")
      return
    }

 
    const cartData = await getCartData()
    let isOOS = false
    //Checking stock availability
    Object.keys(cartData).forEach((item) => {
      if (stockMap[item] == 'off') {
        const alertMsg = `
        We're sorry, but the item ${cartData[item].name} just went out of stock. We will notify you as soon as it is available again. Thank you for your understanding!`
        showAlert(alertMsg)
        isOOS = true
      }
    })

    if (isOOS) return

    const summaryProps = {
      addressDetails : deliveryAddress,
      paymentMode    : paymentMode,
      delSlotId      : delSlot,
      delDate        : delDate,
      delSlotTime    : filteredSlots.filter((slot) => slot.id == delSlot && slot.delTime == delDate)[0].time,
      delType        : delType,
      storeDetails   : storeDetails,
      itemDetails    : cartData,
      instructions   : location?.state?.instructions
    }
    navigate(`/orderSummary`, {state : summaryProps, replace:true})
  }

  const handleAddressChange = async(event) => {

    event.preventDefault()

    showLoader()

    if (!address[event.target.value].storeDetails) {

      const resp = await getNearestStoreDetails(address[event.target.value])
    
      if (!resp.branchId || !resp.locCode) {
        showAlert(<><b>We regret to inform you that delivery is not available for the selected address.</b><br /><br /> Please note that we deliver within a maximum radius of 8km and the selected address lies beyond our delivery range.</>)
        hideLoader()
        return
      } 

      modifyPickupStatus(resp)
      setStoreDetails(resp)
      address[event.target.value].storeDetails = {}
      address[event.target.value].storeDetails.branchId = resp.branchId
      await fetchBranchInfo('Today', resp.branchId)
    } else {

      modifyPickupStatus(address[event.target.value].storeDetails)
      setStoreDetails(address[event.target.value].storeDetails)
      await fetchBranchInfo('Today', address[event.target.value].storeDetails.branchId)
    }

    getStockAvailability({storeId : address[event.target.value].storeDetails.branchId}).then((response => {
      setStockMap(response)
      hideLoader()
    }))

    setSelectedAddrIndex(event.target.value)
    setDeliveryAddress(address[event.target.value])
  }

  const modifyPickupStatus = (storeDetails) => {
    if (storeDetails.selfPickup) setEnableSelfPickup(true)
    else setEnableSelfPickup(false)
    setDelType('delivery')
  }

  const fetchBranchInfo = async(deliveryDate, branchId) => {
    const branchInfo = await getBranchInfo({
      userId       : await getUserId(),
      branchId     : branchId,
      deliveryDate : deliveryDate,
      timeStamp    : Date.now(),
      cartData     : Object.keys(await getCartData())
    })
    setFilteredSlots(branchInfo.slots)
    setDelSlot(null)
    setDelDate(deliveryDate)
    hideLoader()
  }

  const handlePaymentChange = async(event) => {
    setPaymentMode(event.target.value)
  }

  return (
    <motion.div
      initial={{opacity:0}} 
      animate={{opacity:1}}>
    <Box sx={{padding:'4vw', maxWidth:'600px', marginTop:'7vh'}}>

    {
      loading ? <ComponentLoader /> :
    
      <Box>
        <NavHeader />
        <Box>
        <Box style={styles.titleCont}>
          Delivery Details
        </Box>
            <RadioGroup onChange={handleAddressChange} value={selectedAddrIndex}>
            {
              address.map((address, index) => {
                return <Box key={index}>
                  <Box sx={{padding:'10px', margin:'5px 0', background:'#FFF5E8', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px'}}>
                    <FormControlLabel value={index} control={<Radio/>} label={
                      <Box sx={{fontSize:'15px', color:'#404040', fontFamily:'Sans'}}>
                        <Box sx={{fontWeight:'700'}}>
                          {address.userName}
                        </Box>
                        <Box>
                          {address.houseDetails}, {address.streetDetails}
                        </Box>
                        <Box>
                          {address.landmark}
                        </Box>
                        <Box>
                          {address.pincode}
                        </Box>   
                      </Box>
                    } />                  
                  </Box>                                                           
                </Box>
              })
            }
            </RadioGroup>

          {
            showNewAddress ? 
            <Box sx={{display:'flex', flexDirection:'column'}}>
              <Box sx={{mt:2, mb:2}}>
                Add New address
              </Box>
              
              <Box sx={{padding:'10px', background:'#FFF5E8', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px'}}> 
              <form onSubmit={handleSubmit(onFormSubmit)}>
              <Autocomplete onLoad={onAutoCompleteLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  type="text"
                  placeholder="Search for your location"
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `100%`,
                    height: `40px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    marginBottom:'20px',
                    outline: `none`,
                    textOverflow: `ellipses`
                  }}
                />
              </Autocomplete>
              <Button variant='outlined' size='small' onClick={() => printCurrentPosition()}>Use Current Location</Button>
              <Box mb={3} mt={2}>
           
                  {
                    isLoaded ? 
                    (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                        zoom={10}
                        center={window['currentLocation']}
                        options={{mapTypeControl: false, fullscreenControl:false, minZoom:15}}
                        onUnmount={onUnmount}
                      >
                        <Marker position={window['currentLocation']} draggable={true} onDrag={(e) => mapPositionChanged(e.latLng) } />                             
                      </GoogleMap>
                  ) : <></>
                  }
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    defaultValue={newAddressDetail.userName}
                    name="userName"
                    {...register("userName", {
                      required: "Required field",
                      maxLength:{value:100, message:'Name cannot be more than 100 characters'}
                    })}
                    error={Boolean(errors?.userName)}
                    helperText={errors?.userName?.message}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Flat, House no, Apartment"
                    variant="outlined"
                    fullWidth
                    defaultValue={newAddressDetail.houseDetails}
                    name="houseDetails"
                    {...register("houseDetails", {
                      required: "Required field",
                      maxLength:{value:150, message:'Address cannot be more than 150 characters'}
                    })}
                    error={Boolean(errors?.houseDetails)}
                    helperText={errors?.houseDetails?.message}
                  />
                </Box>
                

                <Box mb={3}>
                  <TextField
                    label="Area, Street, City"
                    variant="outlined"
                    fullWidth
                    defaultValue={newAddressDetail.streetDetails}
                    name="streetDetails"
                    {...register("streetDetails", {
                      required: "Required field",
                      maxLength:{value:150, message:'City cannot be more than 150 characters'}
                    })}
                    error={Boolean(errors?.streetDetails)}
                    helperText={errors?.streetDetails?.message}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    placeholder="Enter Landmark"
                    label="Landmark"
                    variant="outlined"
                    fullWidth
                    defaultValue={newAddressDetail.landmark}
                    name="landmark"
                    {...register("landmark", {
                      required: "Required field",
                      maxLength:{value:150, message:'Landmark cannot be more than 150 characters'}
                    })}
                    error={Boolean(errors?.landmark)}
                    helperText={errors?.landmark?.message}
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    label="Pincode"
                    ariant="outlined"
                    fullWidth
                    defaultValue={newAddressDetail.pincode}
                    name="pincode"
                    {...register("pincode", {
                      required: "Required field",
                      pattern: {
                        value: /^[1-9][0-9]{5}$/,
                        message: "Invalid pincode. Pincode must be 6 digits",
                      }
                    })}
                    error={Boolean(errors?.pincode)}
                    helperText={errors?.pincode?.message}
                  />
                </Box>
                  
                <Button onClick={() => closeAddNewAddrForm()} sx={{mr:2}} variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save 
                </Button>
              </form>
                
              </Box>
            </Box> :  
            <Box sx={{marginTop:'10px'}}>
              <Button onClick={() => setShowNewAddress(true)} variant="outlined">Add new address</Button>
            </Box>
          }       
        </Box>


        <Box sx={{mb:1, fontSize:'20px', fontFamily:'Foregen', color:'#a4243d', mt:4}}>
          SELECT DELIVERY SLOT
        </Box>

        <Box mb={3}  sx={{padding:'15px 10px', background:'#FFF5E8', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px'}}>
        
          <Box sx={{mb:2}}>
            <ToggleButtonGroup
                color="primary"
                value={delDate}
                exclusive
                onChange={onDelDateChange}
              >
              <ToggleButton  sx={{textTransform:'none', padding:'3px 20px', 
                             color: delDate == 'Today' ? 'white !important' : 'black !important',
                             background: delDate == 'Today' ? '#a4243d !important' : 'FFF5E8 !important'}} value="Today">Today</ToggleButton>
              <ToggleButton  sx={{textTransform:'none', padding:'3px 20px',
                              color: delDate == 'Tomorrow' ? 'white !important' : 'black !important',
                              background: delDate == 'Tomorrow' ? '#a4243d !important' : 'FFF5E8 !important'}} value="Tomorrow">Tomorrow</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* {
            delDate == 'Today' ? 
            <Box sx={{background:'white', padding:'10px'}}>
              <Box mb={2}>
              Due to an exceptionally high demand, we've temporarily run out of stock.
                </Box>

              <Box sx={{color:'#a4243d', fontSize:'20px'}}>
                Please select a slot for tomorrow to continue order.
              </Box>
            

          </Box> :  */}
          <Select
              labelId="demo-select-small"
              placeholder="Delivery Time"
              fullWidth
              value={delSlot}
              onChange={(e) => changeDeliverySlot(e.target.value)}>

               {
                selectedAddrIndex ? 
                filteredSlots.map((item) => {
                  return ( 
                    <MenuItem key={item.id} disabled={item.isDisabled} value={item.id}>
                      {item.time} {item.isDisabled ? '(Not Available)' : null}
                    </MenuItem> 
                  )
                }) 
                :
                <MenuItem disabled={true} >
                  Please select an address to add slot
                </MenuItem> 
               } 

            </Select>

          {/* } */}

          {/* <Select
            labelId="demo-select-small"
            placeholder="Delivery Time"
            fullWidth
            value={delSlot}
            onChange={(e) => changeDeliverySlot(e.target.value)}>
            {
              timeSlots.map((item) => {
                return (
                  <MenuItem key={item.id} disabled={filteredSlots.indexOf(item.id) == -1} value={item.id}>
                    {item.time} {filteredSlots.indexOf(item.id) == -1 ? '(Not Available)' : null}
                  </MenuItem>
                )
              })
            }
          </Select> */}
        </Box>


        <Box sx={{mb:1, fontSize:'20px', fontFamily:'Foregen', color:'#a4243d'}}>
          SELECT DELIVERY TYPE
        </Box>
        <Box mb={3} sx={{padding:'10px', background:'#FFF5E8', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px'}}>
          
          <Select
            placeholder="Delivery Time"
            fullWidth
            value={delType}
            onChange={(e) => changeDeliveryType(e)}>

            <MenuItem key="delivery" value="delivery">
              Home delivery (₹{deliveryCharge})
            </MenuItem>
            {
              enableSelfPickup ? 
              <MenuItem key="self_pickup" value="self_pickup">
                Self pickup from store  (₹{deliveryCharge - (hasCoupon ? 30 : 35)})
              </MenuItem> : null
            }
          </Select>
        </Box>

        <Box sx={{mb:1, fontSize:'20px', fontFamily:'Foregen', color:'#a4243d'}}>
        PAYMENT MODE
        </Box>
        <Box sx={{padding:'10px', mb:3, background:'#FFF5E8', boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.15)', borderRadius:'5px'}}>
          <FormControl onChange={handlePaymentChange}>
            <RadioGroup defaultValue="online">
              <Box sx={{display:'flex', flexDirection:'column', color:'#404040'}}>
                <FormControlLabel value="online" control={<Radio checked={paymentMode == 'online'} />} label={
                  <Box>
                    Online Payment
                  </Box>
                }/>
                {
                  (hasCoupon || (delType && delType == 'self_pickup')) ? 
                  null : 
                  <FormControlLabel value="cod" control={<Radio />} label={
                    <Box>
                      Cash On Delivery  
                    </Box>
                  }/>
                }
                
              </Box>        
            </RadioGroup>
          </FormControl>
        </Box>
      <Box>
        <Button fullWidth onClick={() => confirmOrder()} variant="contained">
          Confirm
        </Button>
      </Box>
      </Box>
    }
    </Box>
    </motion.div>
  )
}

export default Delivery
